import React, {useMemo} from 'react';

import './styles.scss';
import {DataPoint} from '../../App';
import {useNavigate, useParams} from 'react-router-dom';
import {isEmpty} from '../LinkDetail';
import CountryFlag from '../CountryFlag/CountryFlag';

interface InstitutionDetailProps {
  institutionData: DataPoint;
  data: DataPoint[];
}

const InstitutionDetail = ({institutionData, data}: InstitutionDetailProps) => {
  const groupedLinks = useMemo(() => {
    const groupedLinks: {
      [key: string]: DataPoint[];
    } = {};

    data.forEach((dataPoint) => {
      const type = dataPoint.Country;
      if (!groupedLinks[type]) {
        groupedLinks[type] = [];
      }
      groupedLinks[type].push(dataPoint);
    });

    return groupedLinks;
  }, [data]);

  const navigate = useNavigate();
  const params = useParams();
  const {view} = params;

  const openLinkDetail = (data: DataPoint) => {
    const {
      Country: countryName,
      Institution_name: institutionName,
      CN_Institution_name: cnInstitutionName,
    } = data;
    navigate(
        `/map/${view}/${
        view === 'eu' ? countryName : 'China'
        }/${encodeURIComponent(view === 'eu' ? institutionName : cnInstitutionName)}/${encodeURIComponent(
            view === 'eu' ? cnInstitutionName : institutionName,
        )}`,
    );
  };

  if (!institutionData) return <div>Loading...</div>;

  return (
    <div className={`InstitutionDetail`}>
      {view === 'eu' ? (
        <>
          <h1>{institutionData.Institution_name}</h1>
          <p className="city-name">{isEmpty(institutionData.Institution_city) ? 'N/A' : institutionData.Institution_city}, {institutionData.Country}</p>

          <div>
            <label>Chinese links</label>
            {Object.keys(groupedLinks).map((type) => {
              const data = groupedLinks[type];
              return (
                <div key={type} className="type-group">
                  <p className="type-name">{type}</p>
                  {data.map((link) => {
                    return (
                      <div key={link.CN_Institution_name}>
                        <a onClick={() => openLinkDetail(link)}>
                          {link.CN_Institution_name}
                        </a>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <h1>{institutionData.CN_Institution_name}</h1>
          <p className="city-name">{isEmpty(institutionData.CN_Institution_city) ? 'N/A' : institutionData.CN_Institution_city}, China</p>

          <div>
            <label>European links</label>
            {Object.keys(groupedLinks).map((type) => {
              const data = groupedLinks[type];
              return (
                <div key={type} className="type-group">
                  <p className="type-name">
                    {view === 'cn' && <CountryFlag country={type}/>}
                    <span>{type}</span>
                  </p>
                  {data.map((link) => {
                    return (
                      <div key={link.Institution_name}>
                        <a onClick={() => openLinkDetail(link)}>
                          {link.Institution_name}
                        </a>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default InstitutionDetail;
