import React, {useMemo} from 'react';

import './styles.scss';
import {GroupedData} from '../../App';
import Select, {components} from 'react-select';
import PriorityIcon from '../PriorityIcon';

const {Option} = components;

type FilterOption = {
  value: string;
  label: string;
};

interface FilterBarProps {
  filtersOpen: boolean;
  setFiltersOpen: (filtersOpen: boolean) => void;
  priorityKeys: string[];
  disabled: boolean;
  groupedData: GroupedData;
  filteredLinkType?: string[];
  setFilteredLinkType?: (linkType: string[]) => void;
  filteredInstitutionType?: string[];
  setFilteredInstitutionType?: (institutionType: string[]) => void;
  filteredPriority?: string[];
  setFilteredPriority?: (priority: string[]) => void;
  filteredRisk: string[];
  setFilteredRisk?: (risk: string[]) => void;
  filteredContract?: string[];
  setFilteredContract?: (contract: string[]) => void;
}

function IconOption(props: any) {
  const {
    data: {label},
  } = props;

  return (
    <Option {...props}>
      <div className="priority-option">
        <PriorityIcon priority={label} width={22} />
        <span>{label}</span>
      </div>
    </Option>
  );
}

const FilterBar = ({
  filtersOpen,
  setFiltersOpen,
  priorityKeys = [],
  disabled = false,
  groupedData,
  filteredLinkType = [],
  setFilteredLinkType,
  filteredInstitutionType = [],
  setFilteredInstitutionType,
  filteredPriority = [],
  setFilteredPriority,
  filteredRisk = [],
  setFilteredRisk,
  filteredContract = [],
  setFilteredContract,
}: FilterBarProps) => {
  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const contractOptions = [
    {value: 'yes', label: 'Yes'},
    {value: 'no', label: 'No'},
  ];

  const riskOptions = [
    {value: 'yes', label: 'Yes'},
    {value: 'no', label: 'No'},
  ];

  const priorityOptions = priorityKeys.map((key) => ({
    value: key.replace('Priority_', ''),
    label: key.replace('Priority_', ''),
    icon: <PriorityIcon priority={key.replace('Priority_', '')} />,
  }));

  const {linkTypeOptions, institutionTypeOptions} = useMemo(() => {
    const linkTypes = new Set<string>();
    const institutionTypes = new Set<string>();

    Object.values(groupedData).forEach((city) => {
      Object.values(city.institutions).forEach((institution) => {
        institution.forEach((dataPoint) => {
          const extractedLinkTypes = String(dataPoint.Link_type || '').split(',')
              .filter(Boolean)
              .map((type) => type?.trim().toLowerCase());
          const extractedInstitutionTypes = String(dataPoint.Institution_type || '').split(
              '/',
          )
              .filter(Boolean)
              .map((type) => type?.trim().toLowerCase());
          for (const linkType of extractedLinkTypes) {
            linkTypes.add(linkType);
          }
          for (const institutionType of extractedInstitutionTypes) {
            institutionTypes.add(institutionType);
          }
        });
      });
    });
    const linkTypeOptions: FilterOption[] = Array.from(linkTypes).map(
        (linkType) => ({
          value: linkType,
          label: linkType,
        }),
    );
    const institutionTypeOptions: FilterOption[] = Array.from(
        institutionTypes,
    ).map((institutionType) => ({
      value: institutionType,
      label: institutionType,
    }));

    return {linkTypeOptions, institutionTypeOptions};
  }, [groupedData]);

  const hasFilters =
    !disabled &&
    (filteredLinkType.length > 0 ||
      filteredInstitutionType.length > 0 ||
      filteredPriority.length > 0);

  const clearAllFilters = () => {
    setFilteredLinkType?.([]);
    setFilteredInstitutionType?.([]);
    setFilteredPriority?.([]);
  };

  return (
    <div className="FilterBar">
      <button
        onClick={() => toggleFilters()}
        className="filters-button"
        disabled={disabled}
      >
        <svg
          height="16"
          viewBox="0 0 28 28"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M4.40675 7.25H3C2.44772 7.25 2 6.80228 2 6.25C2 5.69772 2.44772 5.25 3 5.25H4.40675C4.82853 3.94437 6.05398 3 7.5 3C8.94602 3 10.1715 3.94437 10.5933 5.25H25C25.5523 5.25 26 5.69772 26 6.25C26 6.80228 25.5523 7.25 25 7.25H10.5933C10.1715 8.55563 8.94602 9.5 7.5 9.5C6.05398 9.5 4.82853 8.55563 4.40675 7.25ZM5.75 6.25C5.75 5.2835 6.5335 4.5 7.5 4.5C8.4665 4.5 9.25 5.2835 9.25 6.25C9.25 7.2165 8.4665 8 7.5 8C6.5335 8 5.75 7.2165 5.75 6.25Z"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M3 15.25H17.4458C17.8676 16.5556 19.093 17.5 20.5391 17.5C21.9851 17.5 23.2105 16.5556 23.6323 15.25H25C25.5523 15.25 26 14.8023 26 14.25C26 13.6977 25.5523 13.25 25 13.25H23.6323C23.2105 11.9444 21.9851 11 20.5391 11C19.093 11 17.8676 11.9444 17.4458 13.25H3C2.44772 13.25 2 13.6977 2 14.25C2 14.8023 2.44772 15.25 3 15.25ZM20.5391 12.5C19.5726 12.5 18.7891 13.2835 18.7891 14.25C18.7891 15.2165 19.5726 16 20.5391 16C21.5056 16 22.2891 15.2165 22.2891 14.25C22.2891 13.2835 21.5056 12.5 20.5391 12.5Z"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M10.4067 23.25H3C2.44772 23.25 2 22.8023 2 22.25C2 21.6977 2.44772 21.25 3 21.25H10.4067C10.8285 19.9444 12.054 19 13.5 19C14.946 19 16.1715 19.9444 16.5933 21.25H25C25.5523 21.25 26 21.6977 26 22.25C26 22.8023 25.5523 23.25 25 23.25H16.5933C16.1715 24.5556 14.946 25.5 13.5 25.5C12.054 25.5 10.8285 24.5556 10.4067 23.25ZM11.75 22.25C11.75 21.2835 12.5335 20.5 13.5 20.5C14.4665 20.5 15.25 21.2835 15.25 22.25C15.25 23.2165 14.4665 24 13.5 24C12.5335 24 11.75 23.2165 11.75 22.25Z"
            fillRule="evenodd"
          />
        </svg>
        {hasFilters && <span className="filter-indicator" />}
        <span>{filtersOpen ? 'Hide Filters' : 'Show Filters'}</span>
      </button>
      <div
        className={`filters-container ${
          filtersOpen && !disabled ? 'open' : ''
        }`}
      >
        <Select
          options={contractOptions}
          placeholder="Contract available"
          onChange={(selectedOption) => {
            setFilteredContract?.(selectedOption.map((option) => option.value));
          }}
          isClearable
          isMulti
          value={filteredContract.map((type) => ({
            value: type,
            label: type,
          }))}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              minWidth: '140px',
              borderRadius: 8,
              fontSize: 14,
            }),
          }}
        />
        <Select
          options={riskOptions}
          placeholder="Link to PLA"
          onChange={(selectedOption) => {
            setFilteredRisk?.(selectedOption.map((option) => option.value));
          }}
          isClearable
          isMulti
          value={filteredRisk.map((type) => ({
            value: type,
            label: type,
          }))}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              minWidth: '140px',
              borderRadius: 8,
              fontSize: 14,
            }),
          }}
        />
        <Select
          options={priorityOptions}
          placeholder="Priority cooperation areas"
          onChange={(selectedOption) => {
            setFilteredPriority?.(selectedOption.map((option) => option.value));
          }}
          isClearable
          isMulti
          components={{
            Option: IconOption,
          }}
          value={filteredPriority.map((type) => ({
            value: type,
            label: type,
          }))}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: '100%',
              borderRadius: 8,
              fontSize: 14,
            }),
          }}
        />
        <Select
          options={institutionTypeOptions}
          placeholder="Institution type"
          onChange={(selectedOption) => {
            setFilteredInstitutionType?.(
                selectedOption.map((option) => option.value),
            );
          }}
          isClearable
          isMulti
          value={filteredInstitutionType.map((type) => ({
            value: type,
            label: type,
          }))}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: '100%',
              borderRadius: 8,
              fontSize: 14,
            }),
          }}
        />
        <Select
          options={linkTypeOptions}
          placeholder="Link type"
          onChange={(selectedOption) => {
            setFilteredLinkType?.(selectedOption.map((option) => option.value));
          }}
          isMulti
          value={filteredLinkType.map((type) => ({
            value: type,
            label: type,
          }))}
          isClearable
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: '100%',
              minWidth: '180px',
              borderRadius: 8,
              fontSize: 14,
            }),
          }}
        />
        <button
          onClick={() => clearAllFilters()}
          className="clear-filters-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-x"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
          <span>Clear Filters</span>
        </button>
      </div>
    </div>
  );
};

export default FilterBar;
