import React, {ReactNode, useMemo} from 'react';

import './styles.scss';
import {DataPoint} from '../../App';
import CountryFlag from '../CountryFlag/CountryFlag';
import PriorityIcon from '../PriorityIcon';
import ReactTooltip from 'react-tooltip';

interface LinkDetailProps {
  data: DataPoint[];
}

export function isEmpty(value: string) {
  return (
    value === '-' ||
    value === '?' ||
    value === 'n/a' ||
    value === 'N/A' ||
    !value
  );
}

const LinkDetail = ({data = []}: LinkDetailProps) => {
  const linkDataPoint: DataPoint = data[0];
  const [priorityTooltip, setPriorityTooltip] = React.useState<string>('');
  const filteredPriorities = useMemo(() => {
    if (!linkDataPoint) return [];
    const allPriorities = Object.keys(linkDataPoint).filter((key) =>
      key.startsWith('Priority_'),
    );

    return allPriorities.filter((priority) => {
      return linkDataPoint[priority]?.toString().toLowerCase() === 'yes';
    });
  }, [linkDataPoint]);

  if (!linkDataPoint) return <div>Loading...</div>;


  const renderPrioritiesTable = () => {
    if (filteredPriorities.length === 0) return null;

    return (
      <div>
        <div className="priorities">
          {filteredPriorities.map((priority) => {
            return (
              <div
                key={priority}
                data-tip
                data-for="priority-tooltip"
                onMouseEnter={() =>
                  setPriorityTooltip(priority.replace('Priority_', ''))
                }
                onMouseLeave={() => setPriorityTooltip('')}
                onTouchStart={() =>
                  setPriorityTooltip(priority.replace('Priority_', ''))
                }
                onTouchEnd={() => setPriorityTooltip('')}
              >
                <PriorityIcon priority={priority.replace('Priority_', '')} />
              </div>
            );
          })}
          <ReactTooltip padding="10px" id="priority-tooltip">
            <span>{priorityTooltip.replace('_', ' ')}</span>
          </ReactTooltip>
        </div>
      </div>
    );
  };

  const renderYesNo = (stringValue: string) => {
    if (
      !stringValue ||
      stringValue === '?' ||
      stringValue === '-' ||
      stringValue === ''
    ) {
      return <span>-</span>;
    }
    const value = String(stringValue).toLowerCase() === 'yes';
    return (
      <div className="yes-no">
        <span className={value ? 'checked' : ''}>Yes</span>
        <span className={!value ? 'checked' : ''}>No</span>
      </div>
    );
  };

  const renderYesNoPartially = (stringValue: string) => {
    if (
      !stringValue ||
      stringValue === '?' ||
      stringValue === '-' ||
      stringValue === ''
    ) {
      return <span>-</span>;
    }
    const lowercaseValue = String(stringValue).toLowerCase();
    return (
      <div className="yes-no">
        <span className={lowercaseValue === 'yes' ? 'checked' : ''}>Yes</span>
        <span className={lowercaseValue === 'partially' ? 'checked' : ''}>
          Partially
        </span>
        <span className={lowercaseValue === 'no' ? 'checked' : ''}>No</span>
      </div>
    );
  };

  const renderRisk = (risk: string) => {
    if (isEmpty(risk)) {
      return <span>-</span>;
    }

    const riskClasses = new Map<typeof risk, string>([
      ['low', 'low'],
      ['medium', 'medium'],
      ['high', 'high'],
      ['very high', 'very-high'],
    ]);
    return (
      <div className={`risk ${riskClasses.get(risk.toLowerCase())}`}>
        <span>{risk}</span>
      </div>
    );
  };

  const renderTags = (tags: string) => {
    if (isEmpty(tags)) {
      return <span>-</span>;
    }
    // Split and remove empty tags
    const tagsArray = tags
        .split('/')
        .map((tag) => tag.trim())
        .filter((tag) => Boolean);
    return (
      <>
        {tagsArray.map((tag) => {
          return (
            <div key={tag}>
              <span className="tag">{tag}</span>
            </div>
          );
        })}
      </>
    );
  };

  const renderHelper = (
      label: string,
      value: string,
      renderFcOrValue: Function | string | ReactNode,
  ) => {
    if (isEmpty(value)) return null;
    return (
      <>
        <label>{label}</label>
        <span>
          {typeof renderFcOrValue === 'function' ?
            renderFcOrValue() :
            renderFcOrValue}
        </span>
      </>
    );
  };

  return (
    <div className={`LinkDetail`}>
      <h1>{linkDataPoint.Institution_name}</h1>
      <p className="city-name">
        <CountryFlag country={linkDataPoint.Country} />
        <span>
          {isEmpty(linkDataPoint.Institution_city) ?
            '' :
            `${linkDataPoint.Institution_city}, `}
          {linkDataPoint.Country}
        </span>
      </p>

      <div>
        <h1 className="cn-institution">{linkDataPoint.CN_Institution_name}</h1>
        <p className="city-name">
          <CountryFlag country={'China'} />
          <span>
            {isEmpty(linkDataPoint.CN_Institution_city) ?
              '' :
              `${linkDataPoint.CN_Institution_city}, `}
            China
          </span>
        </p>
      </div>

      <div className="info-wrapper">
        <div>
          <label>Institution type</label>
          <span className="tag">{linkDataPoint.CN_Institution_type}</span>
          {(!isEmpty(linkDataPoint.Link_established) ||
            !isEmpty(linkDataPoint.Link_ended)) && (
            <>
              <label>Established</label>
              <span className="established">
                {`${
                  isEmpty(linkDataPoint.Link_established) ?
                    'N/A' :
                    linkDataPoint.Link_established
                } - ${
                  isEmpty(linkDataPoint.Link_ended) ?
                    'N/A' :
                    linkDataPoint.Link_ended
                }`}
              </span>
            </>
          )}
          {renderHelper(
              'Link type',
              linkDataPoint.Link_type,
              <span>{renderTags(linkDataPoint.Link_type)}</span>,
          )}
          {renderHelper(
              'Financial income',
              linkDataPoint.Financial_income,
              <span>{linkDataPoint.Financial_income}</span>,
          )}
          {renderHelper(
              'Non-Financial income',
              linkDataPoint.NonFinancial_income,
              <span>{linkDataPoint.NonFinancial_income}</span>,
          )}
          {renderHelper(
              'Stipend provided',
              linkDataPoint.Stipends,
              <span>{linkDataPoint.Stipends}</span>,
          )}
        </div>
        <div>
          {renderHelper(
              'Is the link contract based?',
              linkDataPoint.Contract,
              <span>{renderYesNo(linkDataPoint.Contract)}</span>,
          )}
          {renderHelper(
              'Is contract disclosed?',
              linkDataPoint.Contract_disclosed,
              <span>{renderYesNo(linkDataPoint.Contract_disclosed)}</span>,
          )}
          {linkDataPoint.Contract_disclosed === 'Yes' && (
            <a
              href={linkDataPoint.Contract_Link}
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          )}
          {!isEmpty(linkDataPoint.ASPI_risk) && (
            // eslint-disable-next-line react/no-unescaped-entities
            <label className="info-text">
              This institution has been linked to the People&apos;s Liberation
              Army by the ASPI China Defense University Tracker
            </label>
          )}
          {renderHelper(
              'Risk of cooperation',
              linkDataPoint.ASPI_risk,
              <span>{renderRisk(linkDataPoint.ASPI_risk)}</span>,
          )}
          {renderHelper(
              'Does the institution hold security credentials?',
              linkDataPoint.ASPI_SecCred,
              <span>{renderTags(linkDataPoint.ASPI_SecCred)}</span>,
          )}
          {renderHelper(
              'History of espionage or misconduct',
              linkDataPoint.ASPI_Espionage,
              <span>{renderYesNo(linkDataPoint.ASPI_Espionage)}</span>,
          )}
          {renderHelper(
              'Is the instituition on the end user list?',
              linkDataPoint.ASPI_Sanctions,
              <span>{renderYesNo(linkDataPoint.ASPI_Sanctions)}</span>,
          )}
        </div>
      </div>
      <div className="info-wrapper">
        <div>
          {renderHelper(
              'Areas of cooperation',
              linkDataPoint.Cooperation_areas,
              <>
                {renderPrioritiesTable()}
                {filteredPriorities?.length > 0 && (<label><i>Further information</i></label>)}
                <span>{linkDataPoint.Cooperation_areas}</span>
              </>,
          )}

          {renderHelper(
              // eslint-disable-next-line max-len
              'Does the institution perform security screening of partners?',
              linkDataPoint.Security_screening,
              <span>{linkDataPoint.Security_screening}</span>,
          )}
          {renderHelper(
              'Did the institution respond to a Freedom of Information Act request about its relations with Chinese partners?',
              linkDataPoint.FOIA_Answer,
              <span>{renderYesNoPartially(linkDataPoint.FOIA_Answer)}</span>,
          )}
          {/* TEMPORARILY DISABLED */}
          {/* {renderHelper(
              // eslint-disable-next-line max-len
              'Other remarks',
              linkDataPoint.Other_remarks,
              <span>{linkDataPoint.Other_remarks}</span>,
          )} */}
        </div>
      </div>
    </div>
  );
};

export default LinkDetail;
