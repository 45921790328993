import React, {useEffect, useMemo, useState} from 'react';

import './styles.scss';
import {useLocation, useParams} from 'react-router-dom';
import {DataPoint, GroupedData} from '../../App';
import Logo from '../Logo';
import SearchBar from '../SearchBar';
import classNames from 'classnames';
import SocialIcons from '../SocialIcons';
import NavigationLinks from '../NavigationLinks';
import {isEmpty} from '../LinkDetail';
import FilterBar from '../FilterBar';

interface HeaderPanelProps {
  country?: string;
  filtersOpen?: boolean;
  setSearchValue?: (value: string) => void;
  searchValue?: string;
  data?: DataPoint[];
  filteredInstitutionType?: string[];
  filteredLinkType?: string[];
  filteredPriority?: string[];
  filteredRisk?: string[];
  filteredContract?: string[];
  setFiltersOpen?: (filtersOpen: boolean) => void;
  setFilteredInstitutionType?: (institutionType: string[]) => void;
  setFilteredLinkType?: (linkType: string[]) => void;
  setFilteredCity?: (city: string | null) => void;
  setFilteredPriority?: (priority: string[]) => void;
  setFilteredRisk?: (risk: string[]) => void;
  setFilteredContract?: (contract: string[]) => void;
  setHoveredCity?: (city: string | null) => void;
}

const HeaderPanel = ({
  country,
  filtersOpen = false,
  data = [],
  searchValue = '',
  setSearchValue,
  filteredInstitutionType,
  filteredLinkType,
  filteredPriority,
  filteredRisk,
  filteredContract,
  setFiltersOpen,
  setFilteredInstitutionType,
  setFilteredLinkType,
  setFilteredCity,
  setFilteredPriority,
  setFilteredRisk,
  setFilteredContract,
  setHoveredCity,
}: HeaderPanelProps) => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const params = useParams();
  const {view} = params;
  const location = useLocation();

  const isMapPage = location.pathname.includes('map');

  const toggleMobileNav = () => {
    setMobileNavVisible(!mobileNavVisible);
  };

  useEffect(() => {
    setMobileNavVisible(false);
  }, [location]);

  const groupedData = useMemo(() => {
    const groupedData: GroupedData = {};
    const lowerCaseValue = searchValue.toLowerCase().trim();
    data.forEach((dataPoint) => {
      if (lowerCaseValue) {
        const nameIndex = (
          view === 'eu' ?
            dataPoint.Institution_name :
            dataPoint.CN_Institution_name
        )
            ?.toLowerCase()
            .search(lowerCaseValue);
        if (nameIndex === -1) {
          return;
        }
      }
      let city =
        view === 'eu' ?
          dataPoint.Institution_city :
          dataPoint.CN_Institution_city;
      if (isEmpty(city)) {
        city = 'N/A';
      }
      const institutionName =
        view === 'eu' ?
          dataPoint.Institution_name :
          dataPoint.CN_Institution_name;
      if (!groupedData[city]) {
        groupedData[city] = {
          country: dataPoint.Country,
          institutions: {},
        };
      }
      if (!groupedData[city].institutions[institutionName]) {
        groupedData[city].institutions[institutionName] = [];
      }
      groupedData[city].institutions[institutionName].push(dataPoint);
    });
    return groupedData;
  }, [data, searchValue, view]);

  const priorityKeys = useMemo(() => {
    const priorityKeys = new Set<string>();
    Object.keys(data[0] || {}).forEach((key) => {
      if (key.includes('Priority_')) {
        priorityKeys.add(key);
      }
    });

    return Array.from(priorityKeys);
  }, [data]);

  return (
    <div className={classNames(['HeaderPanel', {'is-map-page': isMapPage}])}>
      <div className="logo">
        <Logo />
      </div>
      {data.length > 0 && (
        <div className="header-bars-container">
          <div className="header-filter-bar">
            <FilterBar
              filtersOpen={filtersOpen}
              priorityKeys={priorityKeys}
              disabled={false}
              groupedData={groupedData}
              filteredInstitutionType={filteredInstitutionType}
              filteredLinkType={filteredLinkType}
              filteredPriority={filteredPriority}
              filteredRisk={filteredRisk}
              filteredContract={filteredContract}
              setFiltersOpen={setFiltersOpen}
              setFilteredInstitutionType={setFilteredInstitutionType}
              setFilteredLinkType={setFilteredLinkType}
              setFilteredPriority={setFilteredPriority}
              setFilteredRisk={setFilteredRisk}
              setFilteredContract={setFilteredContract}
            />
          </div>
          <div className="header-search-bar">
            <SearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              groupedData={groupedData}
              hideResults={false}
              setFilteredCity={setFilteredCity}
              setHoveredCity={setHoveredCity}
            />
          </div>
        </div>
      )}
      <div className="mobile-header" onClick={toggleMobileNav}>
        {mobileNavVisible ? (
          <svg
            className="close-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="50px"
            height="50px"
          >
            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
          </svg>
        ) : (
          <svg
            className="burger-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="50px"
            height="50px"
          >
            <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" />
          </svg>
        )}
      </div>
      <div
        className={classNames('mobile-navigation-mask', {
          visible: mobileNavVisible,
        })}
        onClick={toggleMobileNav}
      >
        <div
          className={classNames('mobile-navigation', {
            visible: mobileNavVisible,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <NavigationLinks />
          </div>
          <div>
            <SocialIcons />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderPanel;
