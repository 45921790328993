import React from 'react';
import {NavLink} from 'react-router-dom';

export default function NavigationLinks() {
  return (
    <>
      <NavLink className="link" to="/about">
        <span>About</span>
      </NavLink>
      <NavLink className="link" to="/">
        <span>Map</span>
      </NavLink>
      <NavLink className="link" to="/articles">
        <span>Articles</span>
      </NavLink>
      <NavLink className="link" to="/team">
        <span>Team</span>
      </NavLink>
    </>
  );
}
