/* eslint-disable no-unused-vars */
import React, {useMemo} from 'react';
import {ReactComponent as IconAerospace} from '../../assets/icons/priority_icon_aerospace.svg';
import {ReactComponent as IconAgriculture} from '../../assets/icons/priority_icon_agriculture.svg';
import {ReactComponent as IconAI} from '../../assets/icons/priority_icon_ai.svg';
import {ReactComponent as IconBioTech} from '../../assets/icons/priority_icon_biotech.svg';
import {ReactComponent as IconEvs} from '../../assets/icons/priority_icon_evs.svg';
import {ReactComponent as IconMaterials} from '../../assets/icons/priority_icon_materials.svg';
import {ReactComponent as IconMedicine} from '../../assets/icons/priority_icon_medicine.svg';
import {ReactComponent as IconMedTech} from '../../assets/icons/priority_icon_medtech.svg';
import {ReactComponent as IconNavigationSystems} from '../../assets/icons/priority_icon_navigation.svg';
import {ReactComponent as IconNeuroscience} from '../../assets/icons/priority_icon_neuroscience.svg';
import {ReactComponent as IconOceans} from '../../assets/icons/priority_icon_oceans.svg';
import {ReactComponent as IconPharma} from '../../assets/icons/priority_icon_pharma.svg';
import {ReactComponent as IconPolar} from '../../assets/icons/priority_icon_polar.svg';
import {ReactComponent as IconQuantum} from '../../assets/icons/priority_icon_quantum.svg';
import {ReactComponent as IconRobotics} from '../../assets/icons/priority_icon_robotics.svg';
import {ReactComponent as IconSatellite} from '../../assets/icons/priority_icon_satellite.svg';
import {ReactComponent as IconSemiconductor} from '../../assets/icons/priority_icon_semiconductor.svg';
import {ReactComponent as IconSpace} from '../../assets/icons/priority_icon_space.svg';


type Priority =
'Artificial_Intelligence' |
'Quantum_Computing' |
'Semiconductors' |
'Material_Research' |
'Robotics' |
'Aerospace' |
'Electric_Vehicles' |
'Satellites' |
'Navigation_Systems' |
'Space' |
'Oceans' |
'Polar' |
'Medicine' |
'Neuroscience' |
'Pharma' |
'MedTech' |
'BioTech' |
'Agriculture';


const prioritySvgIconsMap: Record<Priority, React.FC<React.SVGProps<SVGSVGElement>>> = {
  'Artificial_Intelligence': IconAI,
  'Quantum_Computing': IconQuantum,
  'Semiconductors': IconSemiconductor,
  'Material_Research': IconMaterials,
  'Robotics': IconRobotics,
  'Aerospace': IconAerospace,
  'Electric_Vehicles': IconEvs,
  'Satellites': IconSatellite,
  'Navigation_Systems': IconNavigationSystems,
  'Space': IconSpace,
  'Oceans': IconOceans,
  'Polar': IconPolar,
  'Medicine': IconMedicine,
  'Neuroscience': IconNeuroscience,
  'Pharma': IconPharma,
  'MedTech': IconMedTech,
  'BioTech': IconBioTech,
  'Agriculture': IconAgriculture,
};


const PriorityIcon = ({priority, width = 28}: { priority: string, width?: number }) => {
  if (!prioritySvgIconsMap[priority as Priority]) {
    return null;
  }
  const Icon = useMemo(() => prioritySvgIconsMap[priority as Priority], [priority]);
  return (
    <div className="priority-icon">
      <Icon width={width} />
    </div>
  );
};

export default PriorityIcon;
